import { Color, MouseEvent, Position, Circle, Line } from "pencil.js";
import AreaBorder from "./AreaBorder";
import Node from "./Node";
import PointPlaceholder from "./PointPlaceholder";

function onClick(e) {
  if (this.disabled) {
    return;
  }

  const event = window.event;
  let isRightMouseButton = false;
  if ("which" in event) {
    isRightMouseButton = event.which === 3;
  } else if ("button" in event) {
    isRightMouseButton = event.button === 2;
  }
  if (isRightMouseButton) {
    if (!e.target.isScene) {
      for (let i = 0; i < this.nodes.length; ++i) {
        if (this.nodes[i].handler === e.target) {
          this.removeNode(i);
          return;
        }
      }
    }
  } else {
    if (e.target.isScene) {
      this.addPoint(e.position);
    } else if (this.nodes.length && e.target === this.nodes[0].handler) {
      this.close();
    }
  }
}

function onMove(e) {
  if (this.disabled) {
    return;
  }

  this.placeholder.hide();
  this.placeholder.afterIndex = -1;

  for (let i = 0; i < this.nodes.length; ++i) {
    if (this.nodes[i].onHandler) {
      return;
    }
  }

  const length = this.closed ? this.nodes.length : this.nodes.length - 1;
  for (let i = 1; i <= length; ++i) {
    const a = this.nodes[i - 1].position;
    const b = this.nodes[i % this.nodes.length].position;
    const c = e.position;
    const vec1 = new Position(b.x - a.x, b.y - a.y);
    const vec2 = new Position(c.x - a.x, c.y - a.y);
    const vec1Length = Math.hypot(vec1.x, vec1.y);
    const t = vec1.dotProduct(vec2) / Math.pow(vec1Length, 2);

    this.placeholder.set(a.clone().lerp(b, t));
    const d = this.placeholder.distance(c.x, c.y);

    if (t >= 0 && t <= 1 && d < 8) {
      this.placeholder.afterIndex = i - 1;
      this.placeholder.show();
      return;
    }
  }
}

function onHover(event) {
  if (!this.closed) {
    return;
  }

  this.notify("onEnter", this);
}

function onLeave(event) {
  if (!this.closed) {
    return;
  }

  this.notify("onLeave", this);
}

export default class EditableArea {
  constructor(scene, options) {
    this.disabled = false;
    this.options = {
      ...{
        stroke: "#000",
        fill: "#d00",
        handlerOptions: {
          fill: new Color("#fff", 0.6),
          stroke: "#000"
        }
      },
      ...options
    };
    this.listeners = [];
    this.nodes = [];
    this.scene = scene;
    this.path = new AreaBorder({
      stroke: this.options.stroke
    });
    scene.add(this.path);
    this.closed = false;

    this.placeholder = new PointPlaceholder();
    this.scene.add(this.placeholder.additionalPoint);

    this.placeholder.additionalPoint.on(MouseEvent.events.click, () => {
      const afterIndex = this.placeholder.afterIndex;
      if (afterIndex >= 0) {
        this.addPoint(this.placeholder.clonePosition(), afterIndex);
      }
    });

    this.scene.on(MouseEvent.events.click, onClick.bind(this));
    this.scene.on(MouseEvent.events.move, onMove.bind(this));
    this.path.on(MouseEvent.events.hover, onHover.bind(this));
    this.path.on(MouseEvent.events.leave, onLeave.bind(this));
  }

  notify(name, data) {
    this.listeners.forEach(listener => {
      if (listener.hasOwnProperty(name)) {
        listener[name](data);
      }
    });
  }

  addPoint(position, afterIndex = -1, notify = true) {
    if (afterIndex === -1 && this.closed) {
      return;
    }

    const node = new Node(position, this, this.options.handlerOptions, () => {
      this.notify("onUpdate", this.getCoords());
    });
    node.handler.on(MouseEvent.events.hover, () => {
      node.onHandler = true;
    });

    node.handler.on(MouseEvent.events.leave, () => {
      node.onHandler = false;
    });

    if (afterIndex > -1) {
      this.path.points.splice(afterIndex + 1, 0, node.position);
    } else {
      this.path.points.push(node.position);
    }

    if (this.nodes.length > 1) {
      const firstNode = this.nodes[0];
      if (firstNode.position !== node.position) {
        this.scene.add(node.handler);
      }
    } else {
      this.scene.add(node.handler);
    }

    if (afterIndex > -1) {
      this.nodes.splice(afterIndex + 1, 0, node);
    } else {
      this.nodes.push(node);
    }

    if (notify) {
      this.notify("onUpdate", this.getCoords());
    }
  }

  close(notify = true) {
    if (!this.nodes.length) {
      return;
    }

    this.path.close();
    this.path.options.fill = new Color(this.options.fill, 0.6);
    this.closed = true;
    if (notify) {
      this.notify("onUpdate", this.getCoords());
    }
  }

  getCoords() {
    const coords = [];
    for (let i = 0; i < this.nodes.length; ++i) {
      const node = this.nodes[i];
      coords.push({
        x: node.x,
        y: node.y
      });
    }

    return coords;
  }

  removeNode(index) {
    const node = this.nodes[index];
    this.scene.remove(node.handler);
    this.nodes.splice(index, 1);
    this.path.points.splice(index, 1);
    this.notify("onUpdate", this.getCoords());
  }

  redraw() {
    this.placeholder.hide();
    this.nodes.forEach(node => {
      node.updatePosition();
    });
  }

  setPoints(points, notify = true) {
    points.forEach(point => {
      this.addPoint(point, -1, false);
    });

    if (notify) {
      this.notify("onUpdate", this.getCoords());
    }
  }

  destroy() {
    let listeners = this.scene.eventListeners[MouseEvent.events.click] || [];
    if (listeners) {
      for (let i = 0; i < listeners.length; ++i) {
        if (listeners[i] === onClick.bind(this)) {
          listeners.splice(i, 1);
          break;
        }
      }
    }

    listeners = this.scene.eventListeners[MouseEvent.events.move] || [];
    for (let i = 0; i < listeners.length; ++i) {
      if (listeners[i] === onMove.bind(this)) {
        listeners.splice(i, 1);
        break;
      }
    }

    this.path.removeAllListener();

    this.nodes.forEach(node => {
      this.scene.remove(node.handler);
    });
    this.nodes = [];
    this.scene.remove(this.path);
    this.path.points = [];
    this.notify("onDestroy");
    this.closed = false;
    this.placeholder.hide();
  }

  disable() {
    this.placeholder.hide();
    this.disabled = true;
    this.notify("disabled");
  }

  enable() {
    this.disabled = false;
    this.notify("enabled");
  }
}
