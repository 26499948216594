<template>
  <div class="image-areas" ref="areaContainer" @contextmenu.prevent>
    <img :src="image" @load="onImageLoaded" ref="image" style="width: 100%;" />
    <canvas class="image-areas__canvas" ref="canvas"></canvas>
  </div>
</template>

<script>
import canvasMixin from "../mixins/canvas";
import { Position, Color, MouseEvent, Polygon } from "pencil.js";

export default {
  mixins: [canvasMixin],
  props: {
    areas: Array,
    highlightedArea: [Number, String, Array]
  },
  computed: {
    container() {
      return this.$refs.areaContainer;
    },
    canvas() {
      return this.$refs.canvas;
    },
    imageElement() {
      return this.$refs.image;
    }
  },
  methods: {
    initCanvas() {
      this.rebuildAreas();
    },
    onResize() {
      this.rebuildAreas();
    },
    rebuildAreas() {
      if (this.areaGeometry) {
        this.areaGeometry.forEach(geometry => {
          this.scene.remove(geometry);
        });
      }

      this.areaGeometry = [];

      this.areas.forEach(area => {
        if (area.coords.length < 3) {
          return;
        }

        const coordCopy = area.coords.map(coord => {
          return [coord.x * this.scene.width, coord.y * this.scene.height];
        });
        const firstPoint = coordCopy.shift();

        const geometry = new Polygon(
          new Position(firstPoint[0], firstPoint[1]),
          coordCopy.map(coord => {
            return [coord[0] - firstPoint[0], coord[1] - firstPoint[1]];
          }),
          area.style.normal
        );
        geometry.area = area;
        this.areaGeometry.push(geometry);
        this.scene.add(geometry);
        geometry.on(MouseEvent.events.hover, e => {
          geometry.options.fill = geometry.area.style.active.fill;
          geometry.options.stroke = geometry.area.style.active.stroke;
        });
        geometry.on(MouseEvent.events.leave, e => {
          geometry.options.fill = geometry.area.style.normal.fill;
          geometry.options.stroke = geometry.area.style.normal.stroke;
        });
        geometry.on(MouseEvent.events.click, e => {
          this.$emit("area:click", geometry.area.id);
        });
        geometry.on(MouseEvent.events.hover, e => {
          this.$emit("area:hover", geometry.area.id);
        });
        geometry.on(MouseEvent.events.leave, e => {
          this.$emit("area:leave", geometry.area.id);
        });
      });
    }
  },
  watch: {
    areas: {
      immediate: true,
      deep: true,
      handler(to) {
        if (this.scene) {
          this.rebuildAreas();
        }
      }
    },
    highlightedArea(to) {
      if (!this.areaGeometry) {
        return;
      }

      if (this.scene) {
        let selectedGeometry = [];
        this.areaGeometry.forEach(geometry => {
          geometry.options.fill = geometry.area.style.normal.fill;
          geometry.options.stroke = geometry.area.style.normal.stroke;

          if (to === null) {
            return;
          }

          if (
            (Array.isArray(to) && to.includes(geometry.area.id)) ||
            geometry.area.id === to
          ) {
            selectedGeometry.push(geometry);
          }
        });

        selectedGeometry.forEach(g => {
          g.options.fill = g.area.style.active.fill;
          g.options.stroke = g.area.style.active.stroke;
        });
      }
    },
    cursor(to) {
      const canvas = this.$refs.canvas;
      if (canvas) {
        canvas.style.cursor = to;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.image-areas {
  position: relative;
  display: inline-block;
  user-select: none;

  &__canvas {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>