import { Circle, MouseEvent } from "pencil.js";
import "@pencil.js/draggable";
import debounce from "@/lib/debounce";

export default class Node {
  constructor(position, area, handlerOptions, onChanged) {
    this.scene = area.scene;
    this.area = area;
    this.x = position.x / this.scene.width;
    this.y = position.y / this.scene.height;
    this.position = position;
    this.handler = new Circle(this.position, 8, handlerOptions);
    this.handler.draggable();

    area.listeners.push({
      disabled: () => {
        this.handler.hide();
      },
      enabled: () => {
        this.handler.show();
      }
    });

    const updateCoords = () => {
      this.x = this.position.x / this.scene.width;
      this.y = this.position.y / this.scene.height;
    };
    this.handler.on(MouseEvent.events.drag, e => {
      updateCoords();
      debounce(() => {
        onChanged(e);
      }, 60);
    });
    this.handler.on(MouseEvent.events.drop, e => {
      updateCoords();
      debounce(() => {
        onChanged(e);
      }, 60);
    });
    this.onHandler = false;
  }

  updatePosition() {
    this.position.set(this.x * this.scene.width, this.y * this.scene.height);
  }
}
