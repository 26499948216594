import ImageAreas from "./components/ImageAreas";
import DrawDesk from "./components/DrawDesk";
import { Color } from "pencil.js";

export const Components = {
  ImageAreas,
  DrawDesk
};

export const Helper = {
  color(color, opacity) {
    return new Color(color, opacity);
  },
  areaStyleSameColor(
    color,
    opacityNormal,
    opacityActive,
    lineOpacityOffset = 0.2
  ) {
    return {
      normal: {
        fill: new Color(color, opacityNormal),
        stroke: new Color(color, Math.min(opacityNormal + lineOpacityOffset, 1))
      },
      active: {
        fill: new Color(color, opacityActive),
        stroke: new Color(color, Math.min(opacityActive + lineOpacityOffset, 1))
      }
    };
  }
};
