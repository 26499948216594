import { Color, Position, Circle } from "pencil.js";

export default class PointPlaceholder {
  constructor() {
    this.listeners = {
      onPointAdd: []
    };

    this.afterIndex = -1;
    this.pointOnSegment = new Position();
    this.additionalPoint = new Circle(this.pointOnSegment, 8, {
      fill: new Color("#fff", 0.6),
      stroke: "gray"
    });
  }

  clonePosition() {
    return this.pointOnSegment.clone();
  }

  hide() {
    this.additionalPoint.hide();
  }

  show() {
    this.additionalPoint.show();
  }

  set(position) {
    this.pointOnSegment.set(position);
  }

  distance(x, y) {
    return Math.hypot(this.pointOnSegment.x - x, this.pointOnSegment.y - y);
  }
}
