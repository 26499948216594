import 'bootstrap';
import '../scss/main.scss';
import './image-area';

const $ = require('jquery');

// Place your custom scripts here
$(() => {
    let lightGalleryElement = document.getElementById('lightgallery');
    if (lightGalleryElement) {
        lightGallery(lightGalleryElement)
    }
});