import { Line } from "pencil.js";

export default class AreaBorder extends Line {
  constructor(options) {
    super([0, 0], [], options);
    this.points.shift();
    this.closed = false;
  }

  trace(path) {
    if (this.points.length < 1) {
      return;
    }

    const firstPoint = this.points[0];
    path.moveTo(firstPoint.x, firstPoint.y);
    for (let i = 1; i < this.points.length; ++i) {
      const point = this.points[i];
      path.lineTo(point.x, point.y);
    }

    if (this.closed) {
      path.lineTo(this.points[0].x, this.points[0].y);
    }

    return this;
  }

  close() {
    this.closed = true;
  }
}
