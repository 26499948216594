import { Scene, Position } from "pencil.js";

export default {
  props: {
    image: String
  },
  computed: {
    container() {
      return null;
    },
    canvas() {
      return null;
    },
    imageElement() {
      return null;
    }
  },
  methods: {
    onReset() {},
    reset() {
      if (this.scene) {
        this.scene.removeAllListener();
      }
      this.onReset();
      this.onImageLoaded();
    },
    onImageLoaded() {
      if (!this.container || !this.canvas || !this.imageElement) {
        throw new Error(
          "Computed property container, canvas or imageElement is not defined"
        );
      }

      this.scene = new Scene(this.canvas);
      this.scene.startLoop();

      const refresh = () => {
        const measures = this.container.getBoundingClientRect();
        this.canvas.width = measures.width;
        this.canvas.height = measures.height;
        this.scene.containerPosition = new Position(
          measures.left + window.scrollX,
          measures.top + window.scrollY
        );
        this.onResize();
      };

      window.addEventListener("resize", refresh);

      this.$on("hook:beforeDestroy", () => {
        this.scene.stopLoop();
        window.removeEventListener("resize", refresh);
      });
      this.initCanvas();
      this.$nextTick(() => {
        refresh();
      });
    },
    onResize() {},
    initCanvas() {}
  }
};
