import { Components, Helper } from 'KplImageArea';
import debounce from 'lodash/debounce';
import Vue from 'vue';
import { each } from '../lib/dom';
import * as turf from '@turf/turf'
import Tooltip from './components/Tooltip';

let leaveTimeout = null;

each('.kpl-image-areas-app', el => {
    new Vue({
        el,
        components: {
            ImageAreas: Components.ImageAreas,
            Tooltip
        },
        data() {
            const areas = JSON.parse(el.dataset.areas);

            return {
                areas: areas.map(area => {
                    const line = turf.lineString( area.coords.map(c => [c.x, c.y]));
                    const centroid = turf.centerOfMass(turf.lineToPolygon(line)).geometry.coordinates;
                    const style = Helper.areaStyleSameColor(area.color, 0.4, 0.8);
                    style.normal.cursor = 'pointer';


                    return {
                        id: area.id,
                        url: area.url,
                        coords: area.coords,
                        contentId: area.contentId,
                        label: area.name,
                        center: {
                            x: centroid[0],
                            y: centroid[1]
                        },
                        style
                    }
                }),
                highlighted: null,
                activeContent: null,
                hovered: null,
                tooltipHovered: false
            }
        },
        computed: {
            tooltipX() {
                if (!this.hovered || !this.$refs.imageArea) {
                    return null;
                }

                const rect = this.getAreaRect();

                return Math.round(rect.width * this.hovered.center.x);
            },
            tooltipY() {
                if (!this.hovered || !this.$refs.imageArea) {
                    return null;
                }

                const rect = this.getAreaRect();

                return Math.round(rect.height * this.hovered.center.y);
            }
        },
        mounted() {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 60);
        },
        methods: {
            getAreaRect() {
                if (!this.$refs.imageArea) {
                    return new DomRect();
                }

                return this.$refs.imageArea.$el.getBoundingClientRect();
            },
            area(id) {
                return this.areas.find(a => a.id === id);
            },
            goToContent(areaId) {
                const area = this.area(areaId);
                console.log(area);
                if (area && area.url) {
                    window.location = area.url
                }
            },
            highlight(areaIds, contentId) {
                this.highlighted = areaIds || null;
                if (contentId) {
                    this.activeContent = contentId
                } else {
                    this.activeContent = null
                }
            },
            showDescription(areaId) {
                clearTimeout(leaveTimeout);
                const area = this.area(areaId);
                this.activeContent = area.contentId;
                this.hovered = area;
            },
            // event handler when leaving area
            hideDescription() {
                clearTimeout(leaveTimeout);
                leaveTimeout = setTimeout(() => {
                    this.activeContent = null;
                    this.hovered = null;
                }, 60);

            },
            // for controlling what content box should be visible
            showOn(contentId) {
                return this.activeContent === contentId
            },
            tooltipEnter() {
                clearTimeout(leaveTimeout);
            },
            tooltipLeave() {
                leaveTimeout = setTimeout(() => {
                    this.activeContent = null;
                    this.hovered = null;
                }, 60);
            }
        }
    });
});
