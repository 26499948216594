<template>
    <div class="tooltip" v-show="visible" :style="{left: `${x}px`, top: `${y}px`}">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            x: [Number, null],
            y: [Number, null]
        },
        computed: {
            visible() {
                return !!((this.x || this.x === 0) && (this.y || this.y === 0));
            }
        },
        mounted() {
            const entered = (event) => {
                this.$emit('mouseenter', event);
            }
            const left = (event) => {
                this.$emit('mouseleave', event);
            }
            this.$el.addEventListener('mouseenter', entered);
            this.$el.addEventListener('mouseleave', left);
            this.$on('hook:beforeDestroy', () => {
                this.$el.removeEventListener('mouseenter', entered);
                this.$el.removeEventListener('mouseleave', left);
            })
        }
    }
</script>

<style lang="scss" scoped>
    .tooltip {
        position: absolute;
    }
</style>