<template>
  <div>
    <div class="draw-desk" ref="drawDesk" @contextmenu.prevent>
      <img :src="image" @load="onImageLoaded" ref="image" style="width: 100%;" />
      <canvas class="draw-desk__canvas" ref="canvas"></canvas>
    </div>
  </div>
</template>

<script>
import {
  Scene,
  Position,
  Color,
  Circle,
  MouseEvent,
  Component,
  Polygon
} from "pencil.js";

import EditableArea from "@/lib/EditableArea";
import canvasMixin from "@/mixins/canvas";
import areaGeometry from "@/mixins/areaGeometry";

export default {
  mixins: [canvasMixin, areaGeometry],
  props: {
    areas: {
      type: Array,
      default() {
        return [];
      }
    },
    showAreas: Boolean,
    selected: String,
    highlighted: String,
    disabled: Boolean,
    options: Object
  },
  computed: {
    container() {
      return this.$refs.drawDesk;
    },
    canvas() {
      return this.$refs.canvas;
    },
    imageElement() {
      return this.$refs.image;
    }
  },
  methods: {
    onReset() {
      this.$emit("udpate:selected", null);
    },
    shouldDrawArea(area) {
      return this.selected !== area.id;
    },
    newArea() {
      if (this.area) {
        this.area.destroy();
      }

      const area = new EditableArea(this.scene, this.options);
      area.listeners.push({
        onEnter: a => {
          this.$emit("entered-area");
        },
        onLeave: a => {
          if (a === area) {
            this.$emit("left-area");
          }
        },
        onUpdate: coords => {
          this.$emit("update", {
            coords,
            closed: area.closed
          });
        }
      });

      if (this.disabled) {
        area.disable();
      }

      this.area = area;
    },
    initCanvas() {
      this.rebuildAreas();
      this.newArea();
    },
    onResize() {
      if (this.area) {
        this.area.redraw();
      }
      this.rebuildAreas();
    },
    setArea(area) {
      this.reset();
      this.$nextTick(() => {
        const points = [];
        area.coords.forEach(coord => {
          points.push(
            Position.from([
              coord.x * this.scene.width,
              coord.y * this.scene.height
            ])
          );
        });
        this.area.setPoints(points, false);
        if (area.closed) {
          this.area.close(false);
        }
        this.$emit("update:selected", area.id);
        this.rebuildAreas();
      });
    }
  },
  watch: {
    selected(to) {
      this.rebuildAreas();
    },
    highlighted(to) {
      this.highlightArea(to);
    },
    disabled(to) {
      if (this.area) {
        this.area[to ? "disable" : "enable"]();
      }
    }
  }
};
</script>

<style lang="scss">
.draw-desk {
  position: relative;
  display: inline-block;
  user-select: none;

  &__canvas {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
