import { Position, MouseEvent, Polygon } from "pencil.js";

export default {
  methods: {
    shouldDrawArea(area) {
      return true;
    },
    highlightArea(area) {
      if (!this.areaGeometry) {
        return;
      }

      if (this.scene) {
        let selectedGeometry = [];
        this.areaGeometry.forEach(geometry => {
          geometry.options.fill = geometry.area.style.normal.fill;
          geometry.options.stroke = geometry.area.style.normal.stroke;

          if (Array.isArray(area)) {
            if (area.includes(geometry.area.id)) {
              selectedGeometry.push(geometry);
            }
          } else if (geometry.area.id === area) {
            selectedGeometry.push(geometry);
          }
        });

        selectedGeometry.forEach(g => {
          g.options.fill = g.area.style.active.fill;
          g.options.stroke = g.area.style.active.stroke;
        });
      }
    },
    rebuildAreas() {
      if (this.areaGeometry) {
        this.areaGeometry.forEach(geometry => {
          geometry.removeAllListener();
          this.scene.remove(geometry);
        });
      }

      this.areaGeometry = [];
      this.areas.forEach(area => {
        if (!this.shouldDrawArea(area) || area.coords.length < 3) {
          return;
        }

        const coordCopy = area.coords.map(coord => {
          return [coord.x * this.scene.width, coord.y * this.scene.height];
        });
        const firstPoint = coordCopy.shift();

        const geometry = new Polygon(
          new Position(firstPoint[0], firstPoint[1]),
          coordCopy.map(coord => {
            return [coord[0] - firstPoint[0], coord[1] - firstPoint[1]];
          }),
          area.style.normal
        );
        geometry.area = area;
        this.areaGeometry.push(geometry);
        this.scene.add(geometry);
        geometry.on(MouseEvent.events.hover, e => {
          geometry.options.fill = geometry.area.style.active.fill;
          geometry.options.stroke = geometry.area.style.active.stroke;
          this.$emit("area:hover", geometry.area);
        });
        geometry.on(MouseEvent.events.leave, e => {
          geometry.options.fill = geometry.area.style.normal.fill;
          geometry.options.stroke = geometry.area.style.normal.stroke;
          this.$emit("area:leave", geometry.area);
        });
        geometry.on(MouseEvent.events.click, e => {
          this.$emit("area:click", geometry.area);
        });
      });
    }
  },
  watch: {
    areas: {
      immediate: true,
      deep: true,
      handler(to) {
        if (this.scene) {
          this.rebuildAreas();
        }
      }
    }
  }
};
